import Image from "next/image";
import React, { useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";

const BeniftContent = () => {
  const [activeVideo, setActiveVideo] = useState(null);
  const windowSize = useWindowSize();

  const handleVideo = () => {
    var iframes = document.querySelectorAll("iframe");
    const elementsArray = Array.from(iframes);
    for (var i = 0; i < elementsArray.length; i++) {
      const src = elementsArray[i].src;
      if (src && src.includes("youtube.com")) {
        // Replace "autoplay=1" with "autoplay=0" to stop the video
        elementsArray[i].src = src.replace("autoplay=1", "autoplay=0");
      }
    }
  };

  return (
    <div className="lg:flex lg:flex-nowrap lg:space-x-10 benifit">
      <div className="flex items-center justify-center lg:w-[43%]">
        <div className="space-y-6">
          <div className="nav-item dark:bg-jacarta-700 dark:border-jacarta-600 rounded-2.5xl bg-white text-center transition-shadow hover:shadow-lg">
            <div className="nav-link nav-link--style-2 group relative flex w-full border-b border-jacarta-100 p-6 text-left dark:border-jacarta-600">
              <div>
                <span className="mb-2 mt-1 block font-display text-xl font-medium text-jacarta-700 group-hover:text-accent dark:text-white ">
                  Impactful Innovations, Experiences, People, Projects & Brands
                </span>
                {/* <div className="nav-link-content">
                  <p className="text-jacarta-500 dark:text-jacarta-300">
                    Launch an Impact DOT campaign to raise funds. Improve your
                    results by supercharging your campaigns with brand, creator,
                    and influencer collabs.
                  </p>
                </div> */}
              </div>
            </div>
          </div>

          <div className="nav-item dark:bg-jacarta-700 dark:border-jacarta-600 rounded-2.5xl bg-white text-center transition-shadow hover:shadow-lg">
            <div className="nav-link nav-link--style-2  group relative flex w-full border-jacarta-100 p-6 text-left dark:border-jacarta-600 dark:bg-transpare">
              <div>
                <span className="mb-2 mt-1 block font-display text-xl font-medium text-jacarta-700 group-hover:text-accent dark:text-white ">
                  Connect, Learn, Collaborate & Co-Create for Impact
                </span>
                {/* <div className="nav-link-content">
                  <p className="text-jacarta-500 dark:text-jacarta-300">
                    Showcase what you stand for. Promote your brand, product,
                    service, or experience by contributing to a cause-aligned
                    Impact DOT campaign.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center lg:w-[57%]">
        <div className="tab-content flex-1">
          <div className="relative mb-12">
            <figure className="hidden lg:flex items-center justify-center">
              <Image
                width={526}
                height={526}
                src="/images/impactoverse-for/impact_Intiative.png"
                alt="benefit"
                className="rounded-full border border-jacarta-100 p-14 dark:border-jacarta-600 object-contain"
              />
              <button
                onClick={() => setActiveVideo("ImpactIntiative")}
                className="js-video-modal-trigger absolute top-1/2 left-1/2 flex h-16 w-16 -translate-y-1/2 -translate-x-1/2 items-center justify-center rounded-full border-2 border-white transition-transform will-change-transform hover:scale-90"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="h-8 w-8 fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
                </svg>
              </button>
            </figure>
          </div>
        </div>
      </div>

      {activeVideo === "ImpactIntiative" && (
        <div className="modal lightbox fade show block">
          <div className="modal-dialog modal-dialog-centered modal-xl w-full">
            <div className="modal-content border-0 bg-transparent">
              <div className="modal-body p-0 relative">
                <button
                  onClick={() => {
                    handleVideo();
                    setActiveVideo(null);
                  }}
                  type="button"
                  className="btn-close position-absolute top-0 end-0 p-3 z-10"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    className="h-6 w-6"
                  >
                    <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z"></path>
                  </svg>
                </button>
                <div
                  id="lightboxCarousel-d7ewe4ig"
                  className="lightbox-carousel carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="position-absolute top-50 start-50 translate-middle text-white">
                        <div className="spinner-border" role="status"></div>
                      </div>
                      <div className="ratio ratio-16x9">
                        <iframe
                          id="video"
                          src="https://www.youtube.com/embed/RI_Vppbqozk"
                          title="Impact Intiative - Supporter for Impact"
                          allow="accelerometer clipboard-write encrypted-media gyroscope picture-in-picture autoplay"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {activeVideo === "Brand" && (
        <div className="modal lightbox fade show block">
          <div className="modal-dialog modal-dialog-centered modal-xl w-full">
            <div className="modal-content border-0 bg-transparent">
              <div className="modal-body p-0 relative">
                <button
                  onClick={() => {
                    handleVideo();
                    setActiveVideo(null);
                  }}
                  type="button"
                  className="btn-close position-absolute top-0 end-0 p-3 z-10"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    className="h-6 w-6"
                  >
                    <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z"></path>
                  </svg>
                </button>
                <div
                  id="lightboxCarousel-d7ewe4ig"
                  className="lightbox-carousel carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="position-absolute top-50 start-50 translate-middle text-white">
                        <div className="spinner-border" role="status"></div>
                      </div>
                      <div className="ratio ratio-16x9">
                        <iframe
                          id="video"
                          src="https://www.youtube.com/embed/usnTfMiEG_4"
                          title="Brands & Influencers"
                          allow="accelerometer clipboard-write encrypted-media gyroscope picture-in-picture autoplay"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BeniftContent;
